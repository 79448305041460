import React, { useState } from 'react';
import WriteOfflineClassPage from './WriteOfflineClassPage';
import WriteOnlineClassPage from './WriteOnlineClassPage';
import SlideBar from '../molecules/SlideBar';

function WriteClassPage() {
  const [classTypeArr, setClassArrType] = useState([true, false]);

  return (
    <div className="flex flex-col items-center justify-center mt-2">
      <SlideBar
        num={2}
        firstText="학원강좌"
        secondText="단과강좌"
        isClickArr={classTypeArr}
        setIsClickArr={setClassArrType}
      />
      <div className="mt-4">
        {classTypeArr[0] ? <WriteOfflineClassPage /> : <WriteOnlineClassPage />}
      </div>
    </div>
  );
}

export default WriteClassPage;
